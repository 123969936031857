import React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import {Link} from "gatsby"


import APPRAISAL_ICON from "../images/content/services/shutterstock_566863540.jpg"
import EQUIPMENT_SALES_ICON from "../images/content/services/shutterstock_76566886.jpg"
import LOGISTIC_ICON from "../images/content/services/shutterstock_509571454.jpg"
import RELOCATION_ICON from "../images/content/services/Boxtruck.jpg"

const OurServices = () => {
  return (
    <>
      <SEO
        title={`Laboratory Asset Management Services`}
        description={`At Surplus Solutions, we provide a wide range of services including appraisal, equipment, logistic, and relocation services. Our team of professionals will ensure expert service on your used equipment needs. Contact us today for more information.`}
        />
      <Layout>
        <div
          className="row column"
        >
          <div className="row">
            <div className="medium-6 columns mb6">
              <div>
                <Link to="/our-services/appraisal-services"><img
                    src={APPRAISAL_ICON}
                    alt="Appraisal Services"
                  /></Link>

                              </div>
            </div>
            <div
              className="medium-6 columns"
            >
              <h2 className="h1">Appraisal Services</h2>
              <hr />
              <p>
                SSLLC’s certified used equipment appraisers can provide you with
                their professional expertise whether it is to appraise a single
                piece of lab equipment or an entire facility.
              </p>
              <p className="mt6">
                <Link
                  to="/our-services/appraisal-services"
                  className="button secondary tiny wide caps"
                >
                  Learn More
                </Link>
              </p>
            </div>
          </div>
          <div className="row">
            <div className="medium-6 columns mb6">
              <div>
                <Link to="sell-equipment">
                  <img
                    src={EQUIPMENT_SALES_ICON}
                    alt="Equipment Sales"
                  />
                </Link>
              </div>
            </div>
            <div
              className="medium-6 columns"
            >
              <h2 className="h1">Equipment Sales</h2>
              <hr />
              <p>
                As a leader in buying and selling lab and biotech equipment, we
                recognize that each customer has unique circumstances. Whether
                it be our used equipment auctions, equipment brokerage and
                consignment, or outright equipment sale; Our team of used
                equipment professionals will ensure maximum returns on your used
                equipment assets.
              </p>
              <p className="mt6">
                <Link
                  to="/sell-equipment"
                  className="button secondary tiny wide caps"
                >
                  Learn More
                </Link>
              </p>
            </div>
          </div>
          <div className="row">
            <div className="medium-6 columns mb6">
              <div>
                <Link to="/our-services/logistic-services">
                  <img
                    src={LOGISTIC_ICON}
                    alt="Logistic Services"
                  />
                </Link>
              </div>
            </div>
            <div
              className="medium-6 columns"
            >
              <h2 className="h1">Logistic Services</h2>
              <hr />
              <p>
                With each equipment purchase, there is a lot to coordinate. Our
                team at SSLLC has developed a network of companies that we trust
                to handle your equipment safely and professionally. No matter
                what the size, we provided a customized option for crating,
                skidding and freight services.
              </p>
              <p className="mt6">
                <Link
                  to="/our-services/logistic-services"
                  className="button secondary tiny wide caps"
                >
                  Learn More
                </Link>
              </p>
            </div>
          </div>
          <div className="row">
            <div className="medium-6 columns mb6">
              <div>
                <Link to="/our-services/relocation-services">
                  <img
                    src={RELOCATION_ICON}
                    alt="Relocation Services"
                  />
                </Link>
              </div>
            </div>
            <div
              className="medium-6 columns"
            >
              <h2 className="h1">Relocation Services</h2>
              <hr />
              <p>
                SSLLC can make the process of relocating your Laboratory or
                entire Facility a seamless transition, with the help of our used
                equipment professional’s expert planning and execution. The
                process of relocating your used surplus equipment is a delicate
                one, and we at Surplus Solutions, take pride in the care that
                goes into the process.
              </p>
              <p className="mt6">
                <Link
                  to="/our-services/relocation-services"
                  className="button secondary tiny wide caps"
                >
                  Learn More
                </Link>
              </p>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default OurServices
